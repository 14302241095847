import React, { useState } from "react";
import { BsPlusSquare } from "react-icons/bs";
import { CiSquareMinus } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import {
  AddUserBookingAsync,
  UpdateTicketsAsync,
} from "../Redux/TicketBookSlice";
const SubCard = (props) => {
  const [TC, setTC] = useState(1);
  const [TotalTickets, setTotalTickets] = useState(null);
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const { UserRole } = useSelector((state) => state.signin);
  return (
    <div className=" w-[95%] rounded-b-lg p-2 border">
      <div className=" w-full text-lg font-semibold text-gray-700 border-b">
        <h1>{props.Name}</h1>
      </div>
      <div className=" w-full text-sm font-normal p-2">
        Tickets: {props.Count}
      </div>
      <div className="flex flex-row justify-start items-center p-2 gap-3">
        <button
          className=" border p-1 font-extrabold text-xl"
          onClick={() => {
            if (TC > 1) {
              setTC(TC - 1);
            }
          }}
        >
          <CiSquareMinus size={23} />
        </button>
        <span>{TC}</span>
        <button
          className=" border p-1 font-extrabold text-xl"
          onClick={() => {
            if (TC < props.Count) {
              setTC(TC + 1);
            }
          }}
        >
          <BsPlusSquare size={20} />
        </button>
        <button
          onClick={() => {
            setLoading(true);
            dispatch(
              AddUserBookingAsync(
                props.MovieId,
                props.TheatreId,
                TC,
                setLoading
              )
            );
            // window.alert(props.MovieId + "  " + props.TheatreId + "  " + TC);
          }}
          disabled={Loading}
          className="border bg-gray-800 border-gray-400 px-2 py-2 w-28 flex justify-center items-center  text-gray-400 text-sm rounded hover:text-gray-900 hover:bg-gray-200"
        >
          {Loading ? <SpinnerCircular size={20} color="white" /> : "Book Now"}
        </button>

        <input
          placeholder="Enter total tickets"
          value={TotalTickets}
          min={1}
          type="number"
          className={`${
            UserRole === "Admin" ? "block" : "hidden"
          } border p-2 py-1.5 outline-none`}
          onChange={(e) => setTotalTickets(e.target.value)}
        />

        <button
          onClick={() => {
            setLoading(true);
            dispatch(
              UpdateTicketsAsync(
                props.MovieId,
                props.TheatreId,
                TotalTickets,
                setLoading
              )
            );
          }}
          disabled={Loading}
          className={`${
            UserRole === "Admin" ? "block" : "hidden"
          } border bg-gray-800 border-gray-400 px-2 py-2 w-28 flex justify-center items-center  text-gray-400 text-sm rounded hover:text-gray-900 hover:bg-gray-200`}
        >
          {Loading ? (
            <SpinnerCircular size={20} color="white" />
          ) : (
            "Update Total"
          )}
        </button>
      </div>
    </div>
  );
};

export default SubCard;
