import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "../Components/DataTable";
import { GetBookingsAsync } from "../Redux/TicketBookSlice";
const Bookings = () => {
  const dispatch = useDispatch();
  const { Bookings } = useSelector((state) => state.ticket);
  useEffect(() => {
    dispatch(GetBookingsAsync());
  }, []);
  const Columns = [
    { field: "bookingId", headerName: "ID", width: 300 },
    { field: "movieName", headerName: "Movie Name", width: 200 },
    { field: "theatreName", headerName: "Theatre Name", width: 200 },
    { field: "numberOfTikcets", headerName: "No. of Tickets", width: 200 },
    { field: "seatnumbers", headerName: "Seats No.s", width: 200 },
  ];
  return (
    <div className="w-full p-3">
      <div className=" text-center p-2 text-lg font-semibold text-gray-800">
        <h1>
          My Bookings <span className=" text-yellow-500">.</span>
        </h1>
      </div>
      <div className="">
        <Datatable Bookings={Bookings} Columns={Columns} />
      </div>
    </div>
  );
};

export default Bookings;
