import { createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "../Api/AppApi";
import {
  MakeErrorToastAsync,
  MakeSuccessToastAsync,
} from "../Components/Toast";
export const Ticket_STATUSES = Object.freeze({
  LOADING: "LOADING",
  DATA: "IDLE",
  ERROR: "ERROR",
});

const initialState = {
  STATUS: Ticket_STATUSES.DATA,
  Bookings: {},
  IsMovieAdded: false,
};

export const TicketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setBookings: (state, action) => {
      return { ...state, Bookings: action.payload };
    },
    setIsMovieAdded: (state, action) => {
      return { ...state, IsMovieAdded: action.payload };
    },
    setTicketStatus: (state, action) => {
      return { ...state, STATUS: action.payload };
    },
  },
});

export const { setBookings, setTicketStatus, setIsMovieAdded } =
  TicketSlice.actions;

export default TicketSlice.reducer;

export const AddUserBookingAsync = (
  MovieId,
  TheatreId,
  TicketCount,
  setLoading
) => {
  return async (dispatch, getState) => {
    dispatch(setTicketStatus(Ticket_STATUSES.LOADING));
    const config = {
      headers: {
        accept: "text/plain",
        authorization: "Bearer " + localStorage.getItem("User-Token"),
      },
    };
    await AuthApi.post(
      "/Add/" +
        getState().signin.User.userId +
        "/" +
        MovieId +
        "/" +
        TheatreId +
        "?Tickets=" +
        TicketCount,
      {},
      config
    )
      .then((response) => {
        dispatch(setIsMovieAdded(true));
        MakeSuccessToastAsync("Tickets booked successfully");
        // window.alert("Tickets booked successfully");
        dispatch(setTicketStatus(Ticket_STATUSES.DATA));
        setLoading(false);
      })
      .catch((error) => {
        dispatch(setIsMovieAdded(false));
        setLoading(false);
        dispatch(setTicketStatus(Ticket_STATUSES.ERROR));
        MakeErrorToastAsync("Something went wrong");
      });
  };
};

export const GetBookingsAsync = () => {
  return async (dispatch, getState) => {
    dispatch(setTicketStatus(Ticket_STATUSES.LOADING));
    AuthApi.defaults.headers = {
      Authorization: "Bearer " + localStorage.getItem("User-Token"),
    };
    AuthApi.get("/GetBookings/" + getState().signin.User.userId)
      .then((response) => {
        dispatch(setBookings(response.data));
        AuthApi.defaults.headers = {};
        dispatch(setTicketStatus(Ticket_STATUSES.DATA));
      })
      .catch((err) => {
        dispatch(setTicketStatus(Ticket_STATUSES.ERROR));
      });
  };
};

export const UpdateTicketsAsync = (
  MovieId,
  TheatreId,
  TicketCount,
  setLoading
) => {
  return async (dispatch, getState) => {
    dispatch(setTicketStatus(Ticket_STATUSES.LOADING));
    const config = {
      headers: {
        accept: "text/plain",
        authorization: "Bearer " + localStorage.getItem("User-Token"),
      },
    };
    await AuthApi.put(
      "/UpdateTicketCount/" +
        TheatreId +
        "/" +
        MovieId +
        "?TicketCount=" +
        TicketCount,
      {},
      config
    )
      .then((response) => {
        MakeSuccessToastAsync("Tickets count updated successfully");
        // window.alert("Tickets booked successfully");
        window.location.reload();
        dispatch(setTicketStatus(Ticket_STATUSES.DATA));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(setTicketStatus(Ticket_STATUSES.ERROR));
        MakeErrorToastAsync("Something went wrong");
      });
  };
};
