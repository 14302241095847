import { createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "../Api/AppApi";
import {
  MakeErrorToastAsync,
  MakeSuccessToastAsync,
} from "../Components/Toast";

export const SIGNIN_STATUSES = Object.freeze({
  LOADING: "LOADING",
  DATA: "IDLE",
  ERROR: "ERROR",
});

const initialState = {
  Username: "",
  Password: "",
  NewPassword: "",
  IsLoggedIn: false,
  User: {},
  Role: "",
  STATUS: SIGNIN_STATUSES.DATA,
};

export const SignInSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {
    setUsername: (state, action) => {
      return { ...state, Username: action.payload };
    },
    setPassword: (state, action) => {
      return { ...state, Password: action.payload };
    },
    setNewPassword: (state, action) => {
      console.log(action.payload);
      return { ...state, NewPassword: action.payload };
    },
    setIsLoggedIn: (state, action) => {
      return { ...state, IsLoggedIn: action.payload };
    },
    setSigninStatus: (state, action) => {
      return { ...state, STATUS: action.payload };
    },
    setUser: (state, action) => {
      return { ...state, User: action.payload };
    },
    setUserRole: (state, action) => {
      return { ...state, UserRole: action.payload };
    },
    SignOut: () => {
      localStorage.clear();
      return { ...initialState };
    },
  },
});

export const {
  setUsername,
  setPassword,
  setIsLoggedIn,
  setSigninStatus,
  setUser,
  setUserRole,
  SignOut,
  setNewPassword,
} = SignInSlice.actions;
export default SignInSlice.reducer;

export const SignInAsync = () => {
  return async (dispatch, getState) => {
    dispatch(setSigninStatus(SIGNIN_STATUSES.LOADING));
    await AuthApi.post("/Login", {
      username: getState().signin.Username,
      password: getState().signin.Password,
    })
      .then((response) => {
        dispatch(setIsLoggedIn(true));
        localStorage.setItem("User-Token", response.data.token);
        dispatch(ValidateUserAsync(response.data.token));
        dispatch(setUserRole(response.data.role));
        MakeSuccessToastAsync("Signin Successful");
        window.location.reload();
        dispatch(setSigninStatus(SIGNIN_STATUSES.DATA));
        dispatch(setIsLoggedIn(true));
      })
      .catch((error) => {
        dispatch(setIsLoggedIn(false));
        dispatch(setSigninStatus(SIGNIN_STATUSES.ERROR));

        MakeErrorToastAsync("Signin UnSuccessful, Please check details");
      });
  };
};

export const ChangePasswordAsync = () => {
  return async (dispatch, getState) => {
    dispatch(setSigninStatus(SIGNIN_STATUSES.LOADING));
    await AuthApi.post("/PasswordReset", {
      emailId: getState().signin.User.user.emailId,
      password: getState().signin.NewPassword,
    })
      .then((response) => {
        MakeSuccessToastAsync("Password changed successfully");
        window.location.reload();
        dispatch(setSigninStatus(SIGNIN_STATUSES.DATA));
      })
      .catch((error) => {
        dispatch(setSigninStatus(SIGNIN_STATUSES.ERROR));

        MakeErrorToastAsync(
          "Password change unsuccessful, Please check details"
        );
      });
  };
};

export const ValidateUserAsync = (token) => {
  return async (dispatch) => {
    dispatch(setSigninStatus(SIGNIN_STATUSES.LOADING));
    await AuthApi.get("/ValidateToken?token=" + token)
      .then((response) => {
        dispatch(setIsLoggedIn(true));
        dispatch(setUser(response.data));
        dispatch(setUserRole(response.data.role));
        dispatch(setSigninStatus(SIGNIN_STATUSES.DATA));
      })
      .catch((error) => {
        dispatch(setSigninStatus(SIGNIN_STATUSES.ERROR));
      });
  };
};
