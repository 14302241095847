import { TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [MovieName, setMovieName] = useState("");
  const navigate = useNavigate();
  const handleSearch = (Name) => {
    navigate("/Movies?moviename=" + Name);
  };
  return (
    <div className="p-2 md:p-5 h-screen flex justify-center md:items-center items-start">
      <div className=" relative p-2 py-10 md:p-10 md:py-20 bg-gray-700 w-full md:w-3/4 flex flex-col md:flex-row justify-center items-center gap-3 rounded-lg">
        <TextField
          placeholder="Search your favourite movie"
          className=" w-full bg-white rounded text-gray-900 font-normal "
          onChange={(e) => setMovieName(e.target.value)}
        />
        <button
          className=" bg-white p-4 rounded font-normal "
          onClick={() => handleSearch(MovieName)}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Home;
