import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import MainCard from "../Components/MainCard";
import SubCard from "../Components/SubCard";
import { GetMovieByIdAsync, Movie_STATUSES } from "../Redux/MovieSlice";
const MovieDetails = () => {
  const { MovieId } = useParams();
  const dispatch = useDispatch();
  const { CurrentMovie, STATUS } = useSelector((state) => state.movie);
  useEffect(() => {
    dispatch(GetMovieByIdAsync(MovieId));
  }, []);

  if (
    Object.keys(CurrentMovie).length == 0 ||
    STATUS === Movie_STATUSES.LOADING
  ) {
    return (
      <div className=" flex h-screen justify-center items-center p-10">
        <SpinnerCircular size={50} color="black" />
      </div>
    );
  }

  return (
    <div className=" p-3">
      <div className=" text-center text-gray-600">
        Movie Details <span className=" text-gray-800">.</span>
      </div>
      <div className="flex flex-col md:flex-row justify-around items-start">
        <div className="p-2 ">
          <MainCard
            Name={CurrentMovie.movieName}
            Thumbnail={CurrentMovie.movieThumbnail}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          {CurrentMovie.theatres.map((theatre) => {
            return (
              <SubCard
                Name={theatre?.theatreName}
                Count={theatre?.ticketCount}
                MovieId={CurrentMovie.movieId}
                TheatreId={theatre?.theatreId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MovieDetails;
