import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import MovieCard from "../Components/MovieCard";
import {
  GetAllMoviesAsync,
  GetMovieBySearchAsync,
  Movie_STATUSES,
} from "../Redux/MovieSlice";

const Movies = () => {
  const { Movies, STATUS } = useSelector((state) => state.movie);
  const params = new URLSearchParams(useLocation().search);
  const [MovieName, setMovieName] = useState(null || params.get("moviename"));
  const dispatch = useDispatch();
  useEffect(() => {
    if (MovieName !== null) {
      dispatch(GetMovieBySearchAsync(MovieName));
    } else {
      dispatch(GetAllMoviesAsync());
    }
  }, [MovieName]);

  if (STATUS === Movie_STATUSES.LOADING) {
    return (
      <div className=" flex h-screen justify-center items-center p-10">
        <SpinnerCircular size={50} color="black" />
      </div>
    );
  }

  return (
    <>
      <div className=" text-center p-2 text-lg font-semibold text-gray-800">
        <h1>
          Movies <span className=" text-yellow-500">.</span>
        </h1>
      </div>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 p-5">
        {/* <MovieCard Movie={Movie1} /> */}
        {Movies.length > 0
          ? Movies?.map((movie) => {
              return <MovieCard Movie={movie} />;
            })
          : "No records found"}
      </div>
    </>
  );
};

export default Movies;
