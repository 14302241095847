import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const Datatable = (props) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGrid
        rows={props.Bookings}
        getRowId={(row) => row.bookingId}
        columns={props.Columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 5]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
