import { configureStore } from "@reduxjs/toolkit";
import movieReducer from "./MovieSlice";
import signInReducer from "./SignInSlice";
import signUpReducer from "./SignUpSlice";
import theatreReducer from "./TheatreSlice";
import ticketReducer from "./TicketBookSlice";

const store = configureStore({
  reducer: {
    signin: signInReducer,
    signup: signUpReducer,
    movie: movieReducer,
    theatre: theatreReducer,
    ticket: ticketReducer,
  },
});

export default store;
