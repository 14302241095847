import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";

import {
  ResetState,
  SIGNUP_STATUSES,
  SignUpAsync,
  UserNameAvailable,
  setEmailId,
  setFirstName,
  setIsUsernameAvailable,
  setLastName,
  setPassword,
  setPhoneNumber,
  setUserName,
} from "../Redux/SignUpSlice";

const SignUp = () => {
  const dispatch = useDispatch();
  const [IsEmailValid, setIsEmailValid] = useState(false);
  const [EmailMessage, setEmailMessage] = useState("");
  const [IsMobileValid, setIsMobileValid] = useState(false);
  const [MobileMessage, setMobileMessage] = useState("");
  const {
    EmailId,
    Password,
    STATUS,
    FirstName,
    LastName,
    PhoneNumber,
    UserName,
    IsUsernameAvailable,
    IsSignUpSuccess,
  } = useSelector((state) => state.signup);

  const emailValidation = (email) => {
    dispatch(setEmailId(email));
    if (email === "") {
      setEmailMessage("");
      return;
    }
    var regexp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regexp.test(String(email).toLowerCase())) {
      setIsEmailValid(true);
      setEmailMessage("");
    } else {
      setIsEmailValid(false);
      setEmailMessage("Please enter valid email id");
    }
  };

  const mobileValidation = (mobile) => {
    var regexp = /^[7-9][0-9]{9}$/;
    dispatch(setPhoneNumber(mobile));
    if (mobile === "") {
      setMobileMessage("");
      return;
    }
    if (regexp.test(mobile)) {
      setIsMobileValid(true);
      setMobileMessage("");
    } else {
      setIsMobileValid(false);
      setMobileMessage("Enter valid mobile number");
    }
  };

  const navigate = useNavigate();

  const CheckUserNameAvailibility = (username) => {
    dispatch(setUserName(username));
    if (username === "") {
      dispatch(setIsUsernameAvailable(true));
      return;
    }
    dispatch(UserNameAvailable());
  };
  useEffect(() => {
    if (IsSignUpSuccess) {
      navigate("/SignIn");
      dispatch(ResetState());
    }
  });
  return (
    <div className="w-full flex flex-col items-center justify-center p-5 pt-20">
      <div className="w-full text-center font-medium text-lg p-5">
        <h1>Tell Us About Yourself</h1>
      </div>
      <form
        action="javascript:void(0)"
        onSubmit={() => {
          dispatch(SignUpAsync());
        }}
        className=" flex flex-wrap items-center justify-between gap-3 w-full md:w-[35%] p-2 text-sm"
      >
        <TextField
          id="firstname"
          label="First Name"
          variant="outlined"
          required
          className=" w-full md:w-[45%] text-sm"
          onChange={(e) => dispatch(setFirstName(e.target.value))}
          value={FirstName}
        />
        <TextField
          id="lastname"
          label="Last Name"
          variant="outlined"
          required
          className=" w-full md:w-[45%] text-sm"
          onChange={(e) => dispatch(setLastName(e.target.value))}
          value={LastName}
        />
        <TextField
          id="email-id"
          label="Email"
          variant="outlined"
          required
          className=" w-full  text-sm"
          onChange={(e) => emailValidation(e.target.value)}
          value={EmailId}
          error={EmailMessage !== ""}
        />
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          required
          className=" w-full  text-sm"
          onChange={(e) => CheckUserNameAvailibility(e.target.value)}
          value={UserName}
          error={!IsUsernameAvailable}
        />
        <TextField
          id="PhoneNumber"
          label="PhoneNumber"
          variant="outlined"
          required
          className=" w-full  text-sm"
          inputProps={{
            maxLength: 10,
          }}
          onChange={(e) => mobileValidation(e.target.value)}
          value={PhoneNumber}
          helperText={"Please start your phonenumber with 9 or 8 or 7"}
          error={MobileMessage !== ""}
        />
        <TextField
          id="Password"
          label="Password"
          variant="outlined"
          required
          className=" w-full  text-sm"
          onChange={(e) => dispatch(setPassword(e.target.value))}
          value={Password}
          inputProps={{
            minLength: 8,
          }}
          helperText={"E.g. Test@12345"}
        />
        <button
          type="submit"
          disabled={IsEmailValid || IsMobileValid ? false : true}
          className=" w-full text-sm p-2 py-3 rounded bg-gray-700 text-gray-200 flex justify-center items-center"
        >
          {STATUS === SIGNUP_STATUSES.LOADING ? (
            <SpinnerCircular color="white" size={20} speed={120} />
          ) : (
            "SignUp"
          )}
        </button>
      </form>
    </div>
  );
};

export default SignUp;
