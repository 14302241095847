import { createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "../Api/AppApi";
export const Theatre_STATUSES = Object.freeze({
  LOADING: "LOADING",
  DATA: "IDLE",
  ERROR: "ERROR",
});

const initialState = {
  STATUS: Theatre_STATUSES.DATA,
  Theatres: {},
  CurrentTheatre: {},
};

export const TheatreSlice = createSlice({
  name: "theatre",
  initialState,
  reducers: {
    setTheatreStatus: (state, action) => {
      return { ...state, STATUS: action.payload };
    },
    setTheatres: (state, action) => {
      return { ...state, Theatres: action.payload };
    },
    setCurrentTheatre: (state, action) => {
      return { ...state, CurrentTheatre: action.payload };
    },
  },
});

export const { setCurrentTheatre, setTheatreStatus, setTheatres } =
  TheatreSlice.actions;

export default TheatreSlice.reducer;

export const GetAllTheatresAsync = () => {
  return async (dispatch) => {
    dispatch(setTheatreStatus(Theatre_STATUSES.LOADING));
    await AuthApi.get("/GetAllTheatresWithMovieNames")
      .then((response) => {
        dispatch(setTheatres(response.data));
        dispatch(setTheatreStatus(Theatre_STATUSES.DATA));
      })
      .catch((error) => {
        dispatch(setTheatreStatus(Theatre_STATUSES.ERROR));
      });
  };
};

export const GetTheatreByIdAsync = (TheatreId) => {
  return async (dispatch) => {
    dispatch(setTheatreStatus(Theatre_STATUSES.LOADING));
    await AuthApi.get("/GetTheatreById?TheatreId=" + TheatreId)
      .then((response) => {
        dispatch(setCurrentTheatre(response.data));
        dispatch(setTheatreStatus(Theatre_STATUSES.DATA));
      })
      .catch((error) => {
        dispatch(setTheatreStatus(Theatre_STATUSES.ERROR));
      });
  };
};
