import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import MainCard from "../Components/MainCard";
import SubCard from "../Components/SubCard";
import { GetTheatreByIdAsync, Theatre_STATUSES } from "../Redux/TheatreSlice";
const TheatreDetails = () => {
  const { TheatreId } = useParams();
  const dispatch = useDispatch();
  const { CurrentTheatre, STATUS } = useSelector((state) => state.theatre);
  useEffect(() => {
    dispatch(GetTheatreByIdAsync(TheatreId));
  }, []);

  if (
    STATUS === Theatre_STATUSES.LOADING ||
    Object.keys(CurrentTheatre).length == 0
  ) {
    return (
      <div className=" flex h-screen justify-center items-center p-10">
        <SpinnerCircular size={50} color="black" />
      </div>
    );
  }

  return (
    <div className=" p-3">
      <div className=" text-center text-gray-600">
        Theatre Details <span className=" text-gray-800">.</span>
      </div>
      <div className="flex flex-col md:flex-row justify-around items-start">
        <div className="p-2 ">
          <MainCard
            Name={CurrentTheatre.theatreName}
            Thumbnail={CurrentTheatre.theatreThumbnail}
          />
        </div>
        <div className="w-full flex flex-col gap-2">
          {CurrentTheatre.movies.map((movie) => {
            return (
              <SubCard
                Name={movie?.movieName}
                Count={movie?.ticketCount}
                TheatreId={CurrentTheatre.theatreId}
                MovieId={movie?.movieId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TheatreDetails;
