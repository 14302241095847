import React from "react";

export const ButtonPrimary = ({ Name, Link }) => {
  return (
    <a href={Link}>
      <button className=" border border-gray-400 px-2 py-1 w-20 text-gray-400 text-sm rounded hover:text-gray-900 hover:bg-gray-200">
        {Name}
      </button>
    </a>
  );
};

export const ButtonSecondary = ({ Name, Link }) => {
  return (
    <a href={Link}>
      <button className=" border border-gray-400 px-2 py-1 w-20 text-gray-900 bg-gray-200 text-sm rounded hover:text-gray-400 hover:bg-transparent">
        {Name}
      </button>
    </a>
  );
};
