import TextField from "@mui/material/TextField";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpinnerCircular } from "spinners-react";
import {
  SIGNIN_STATUSES,
  SignInAsync,
  setPassword,
  setUsername,
} from "../Redux/SignInSlice";

const SignIn = () => {
  const dispatch = useDispatch();
  const { Username, Password, IsLoggedIn, STATUS } = useSelector(
    (state) => state.signin
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (IsLoggedIn) {
      navigate("/");
    }
  });

  return (
    <div className="w-full flex flex-col items-center justify-center p-5 pt-20">
      <div className=" w-full text-center font-medium text-lg p-5">
        <h1>SignIn</h1>
      </div>
      <form
        action="javascript:void(0)"
        onSubmit={() => {
          dispatch(SignInAsync());
        }}
        className=" flex flex-col items-center justify-between gap-3 w-full md:w-[25%] p-2 text-sm"
      >
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          required
          className=" w-full text-sm"
          onChange={(e) => dispatch(setUsername(e.target.value))}
          value={Username}
        />
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          required
          className=" w-full"
          type="password"
          onChange={(e) => dispatch(setPassword(e.target.value))}
          value={Password}
        />
        <button
          type="submit"
          // onClick={() => dispatch(SignInAsync())}
          className=" w-full text-sm p-2 py-3 rounded bg-gray-700 text-gray-200 flex justify-center items-center"
        >
          {STATUS === SIGNIN_STATUSES.LOADING ? (
            <SpinnerCircular color="white" size={20} speed={120} />
          ) : (
            "SignIn"
          )}
        </button>
      </form>
      <div className=" text-sm">
        <p>
          Don't have an account?{" "}
          <a href="/SignUp" className=" text-gray-600">
            Create here
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignIn;
