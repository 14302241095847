import { createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "../Api/AppApi";
export const Movie_STATUSES = Object.freeze({
  LOADING: "LOADING",
  DATA: "IDLE",
  ERROR: "ERROR",
});

const initialState = {
  STATUS: Movie_STATUSES.DATA,
  Movies: {},
  CurrentMovie: {},
};

export const MovieSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {
    setMovieStatus: (state, action) => {
      return { ...state, STATUS: action.payload };
    },
    setMovies: (state, action) => {
      return { ...state, Movies: action.payload };
    },
    setCurrentMovie: (state, action) => {
      return { ...state, CurrentMovie: action.payload };
    },
  },
});

export const { setCurrentMovie, setMovieStatus, setMovies } =
  MovieSlice.actions;

export default MovieSlice.reducer;

export const GetAllMoviesAsync = () => {
  return async (dispatch) => {
    dispatch(setMovieStatus(Movie_STATUSES.LOADING));
    await AuthApi.get("/GetAllMoviesWithTheatreNames")
      .then((response) => {
        dispatch(setMovies(response.data));
        dispatch(setMovieStatus(Movie_STATUSES.DATA));
      })
      .catch((error) => {
        dispatch(setMovieStatus(Movie_STATUSES.ERROR));
      });
  };
};

export const GetMovieByIdAsync = (MovieId) => {
  return async (dispatch) => {
    dispatch(setMovieStatus(Movie_STATUSES.LOADING));
    await AuthApi.get("/GetMovieById?MovieId=" + MovieId)
      .then((response) => {
        dispatch(setCurrentMovie(response.data));
        dispatch(setMovieStatus(Movie_STATUSES.DATA));
      })
      .catch((error) => {
        dispatch(setMovieStatus(Movie_STATUSES.ERROR));
      });
  };
};

export const GetMovieBySearchAsync = (MovieName) => {
  return async (dispatch) => {
    dispatch(setMovieStatus(Movie_STATUSES.LOADING));
    await AuthApi.get("/GetMovieBySearch/" + MovieName)
      .then((response) => {
        dispatch(setMovies(response.data));
        dispatch(setMovieStatus(Movie_STATUSES.DATA));
      })
      .catch((error) => {
        dispatch(setMovieStatus(Movie_STATUSES.ERROR));
      });
  };
};
