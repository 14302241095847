import React, { useState } from "react";
import ChangePassword from "../Components/ChangePassword";
const Profile = () => {
  const ProfileInt = () => {
    return (
      <div className=" w-full p-1 flex flex-col justify-start items-start gap-3">
        <h1 className=" text-lg md:text-2xl text-gray-800 p-1 border-b w-full">
          Shivam Sutar
        </h1>
        <div className=" text-gray-500 flex flex-col justify-between items-start gap-4">
          <h1 className="p-1">
            UserName:{" "}
            <span className=" text-gray-800 font-medium border p-1">
              shivamsutar
            </span>
          </h1>
          <h1 className="p-1">
            Email:{" "}
            <span className=" text-gray-800 font-medium border p-1">
              shivamsutar@gmail.com
            </span>
          </h1>
          <h1 className="p-1">
            Contact Number:{" "}
            <span className=" text-gray-800 font-medium border p-1">
              +91-984494949494
            </span>
          </h1>
        </div>
      </div>
    );
  };

  const [view, setView] = useState(<ProfileInt />);
  return (
    <div className="p-10 flex flex-col md:flex-row justify-start items-start gap-20">
      <div className="p-1 w-48">
        <ul>
          <button className=" p-2">
            <li onClick={() => setView(<ProfileInt />)}>Profile</li>
          </button>
          <button className=" p-2">
            <li onClick={() => setView(<ChangePassword />)}>Change Password</li>
          </button>
        </ul>
      </div>
      {view}
    </div>
  );
};

export default Profile;
