import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { SignOut } from "../Redux/SignInSlice";
import { ButtonPrimary, ButtonSecondary } from "./Buttons";

const Navbar = () => {
  const [IsNavOpen, setIsNavOpen] = useState(false);
  const { IsLoggedIn } = useSelector((state) => state.signin);

  const dispatch = useDispatch();
  return (
    <div className=" flex flex-col md:flex-row md:justify-between md:items-center  bg-gray-800 p-2 relative">
      <div className=" text-lg text-gray-200 font-medium">
        <h1>
          <a href="/" className=" hover:text-gray-100">
            BookMyMovie{" "}
            <span className=" text-cyan-500 hover:text-cyan-400">.</span>
          </a>
        </h1>
      </div>
      <div
        className={`p-2 flex flex-col md:flex-row md:items-center md:justify-between gap-10 w-full md:w-[60%] bg-gray-800 absolute left-0 md:relative md:top-0 duration-500 ${
          IsNavOpen ? "top-10 " : "-top-[400px]"
        }`}
      >
        <ul className=" flex flex-col md:flex-row md:justify-start md:items-center gap-5 text-sm text-gray-400 ">
          <li>
            <a className=" hover:text-gray-300" href="/">
              Home
            </a>
          </li>
          <li>
            <a className=" hover:text-gray-300" href="/Movies">
              Movies
            </a>
          </li>
          <li>
            <a className=" hover:text-gray-300" href="/Theatres">
              Theatres
            </a>
          </li>
          <li className={`${IsLoggedIn ? "block" : "hidden"}`}>
            <a className=" hover:text-gray-300" href="/Bookings">
              Bookings
            </a>
          </li>
          <li className={`${IsLoggedIn ? "block" : "hidden"}`}>
            <a className=" hover:text-gray-300" href="/Profile">
              Profile
            </a>
          </li>
        </ul>
        <div className="">
          <ul
            className={`flex flex-col md:flex-row md:items-center md:justify-end gap-4 z-50 ${
              IsLoggedIn ? "hidden" : "block"
            }`}
          >
            <li>
              <ButtonPrimary Name={"SignIn"} Link={"/SignIn"} />
            </li>
            <li>
              <ButtonSecondary Name={"SignUp"} Link={"/SignUp"} />
            </li>
          </ul>
          <ul
            className={`flex flex-col md:flex-row md:items-center md:justify-end gap-4 z-50 ${
              IsLoggedIn ? "block" : "hidden"
            }`}
          >
            <li>
              <button
                onClick={() => {
                  dispatch(SignOut());
                }}
                className=" border border-gray-400 px-2 py-1 w-20 text-gray-900 bg-gray-200 text-sm rounded hover:text-gray-400 hover:bg-transparent"
              >
                Signout
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className=" block md:hidden absolute right-5">
        <button
          className={`${IsNavOpen ? "block" : "hidden"} duration-500`}
          onClick={() => setIsNavOpen(!IsNavOpen)}
        >
          <AiOutlineClose size={20} color="white" />
        </button>
        <button
          className={`${IsNavOpen ? "hidden" : "block"} duration-500`}
          onClick={() => setIsNavOpen(!IsNavOpen)}
        >
          <BsFillMenuButtonWideFill size={20} color="white" />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
