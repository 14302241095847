import React from "react";
const MovieCard = (props) => {
  return (
    <div className=" w-[95%] rounded-b-lg p-2">
      <a href={"/Movies/" + props.Movie.movieId}>
        <div className="shadow-md shadow-gray-800 rounded-lg w-60 h-80">
          <img
            src={"data:image/jpg;base64," + props.Movie.movieThumbnail + ""}
            alt="Image not found"
            className="rounded-lg object-fill w-full h-full"
          />
        </div>
        <div className="p-1 text-lg font-medium text-center">
          <h1>{props.Movie.movieName}</h1>
        </div>
      </a>
    </div>
  );
};

export default MovieCard;
