import { createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "../Api/AppApi";
import {
  MakeErrorToastAsync,
  MakeSuccessToastAsync,
} from "../Components/Toast";
import { SIGNIN_STATUSES } from "./SignInSlice";
export const SIGNUP_STATUSES = Object.freeze({
  LOADING: "LOADING",
  DATA: "IDLE",
  ERROR: "ERROR",
});

const initialState = {
  EmailId: "",
  Password: "",
  FirstName: "",
  LastName: "",
  PhoneNumber: "",
  UserName: "",
  STATUS: SIGNUP_STATUSES.DATA,
  UserNameStatus: SIGNIN_STATUSES.DATA,
  IsUsernameAvailable: true,
  IsSignUpSuccess: false,
};

export const SignUpSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setEmailId: (state, action) => {
      return { ...state, EmailId: action.payload };
    },
    setPassword: (state, action) => {
      return { ...state, Password: action.payload };
    },
    setSignupStatus: (state, action) => {
      return { ...state, STATUS: action.payload };
    },
    setFirstName: (state, action) => {
      return { ...state, FirstName: action.payload };
    },
    setLastName: (state, action) => {
      return { ...state, LastName: action.payload };
    },
    setUserName: (state, action) => {
      return { ...state, UserName: action.payload };
    },
    setPhoneNumber: (state, action) => {
      return { ...state, PhoneNumber: action.payload };
    },
    setUserNameStatus: (state, action) => {
      return { ...state, UserNameStatus: action.payload };
    },
    setIsUsernameAvailable: (state, action) => {
      return { ...state, IsUsernameAvailable: action.payload };
    },
    setIsSignUpSuccess: (state, action) => {
      return { ...state, IsSignUpSuccess: action.payload };
    },
    ResetState: () => {
      return { ...initialState };
    },
  },
});

export const {
  setEmailId,
  setPassword,
  setFirstName,
  setLastName,
  setUserName,
  setSignupStatus,
  setPhoneNumber,
  setUserNameStatus,
  setIsUsernameAvailable,
  setIsSignUpSuccess,
  ResetState,
} = SignUpSlice.actions;

export default SignUpSlice.reducer;

export const SignUpAsync = () => {
  return async (dispatch, getState) => {
    dispatch(setSignupStatus(SIGNUP_STATUSES.LOADING));

    await AuthApi.post("/Register", {
      emailId: getState().signup.EmailId,
      password: getState().signup.Password,
      contactNumber: getState().signup.PhoneNumber,
      firstName: getState().signup.FirstName,
      lastName: getState().signup.LastName,
      userName: getState().signup.UserName,
    })
      .then((response) => {
        MakeSuccessToastAsync("Signup Successful");
        dispatch(setIsSignUpSuccess(true));
        dispatch(setSignupStatus(SIGNUP_STATUSES.DATA));
      })
      .catch((error) => {
        dispatch(setIsSignUpSuccess(false));
        dispatch(setSignupStatus(SIGNUP_STATUSES.ERROR));
        MakeErrorToastAsync("Signup UnSuccessful, Please check details");
      });
  };
};

export const UserNameAvailable = () => {
  return async (dispatch, getState) => {
    dispatch(setUserNameStatus(SIGNUP_STATUSES.LOADING));
    await AuthApi.post(
      "/UsernameAvailable?Username=" + getState().signup.UserName
    )
      .then((response) => {
        if (response.data) {
          dispatch(setIsUsernameAvailable(true));
          dispatch(setUserNameStatus(SIGNUP_STATUSES.DATA));
        } else {
          dispatch(setIsUsernameAvailable(false));
        }
      })
      .catch((error) => {
        dispatch(setIsUsernameAvailable(false));
        dispatch(setUserNameStatus(SIGNUP_STATUSES.ERROR));
        // MakeErrorToastAsync("Signin UnSuccessful, Please check details");
      });
  };
};
