import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Screens/Home";
import Navbar from "./Components/Navbar";
import Movies from "./Screens/Movies";
import Theatres from "./Screens/Theatres";
import SignIn from "./Screens/SignIn";
import SignUp from "./Screens/SignUp";
import { useEffect } from "react";
import { SIGNIN_STATUSES, ValidateUserAsync } from "./Redux/SignInSlice";
import { useDispatch, useSelector } from "react-redux";
import MovieDetails from "./Screens/MovieDetails";
import TheatreDetails from "./Screens/TheatreDetails";
import Bookings from "./Screens/Bookings";
import Profile from "./Screens/Profile";
import Toast from "./Components/Toast";
function App() {
  const token = localStorage.getItem("User-Token");
  const dispatch = useDispatch();
  const { IsLoggedIn, STATUS } = useSelector((state) => state.signin);
  useEffect(() => {
    if (token !== null && token !== undefined) {
      dispatch(ValidateUserAsync(token));
    }
  }, []);
  return (
    <BrowserRouter>
      <Toast />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Movies" element={<Movies />} />
        <Route path="/Theatres" element={<Theatres />} />
        <Route path="/SignIn" element={<SignIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route
          path="/Bookings"
          element={
            IsLoggedIn ? (
              <Bookings />
            ) : STATUS === SIGNIN_STATUSES.LOADING ? (
              ""
            ) : (
              <SignIn />
            )
          }
        />
        <Route
          path="/Profile"
          element={
            IsLoggedIn ? (
              <Profile />
            ) : STATUS === SIGNIN_STATUSES.LOADING ? (
              ""
            ) : (
              <SignIn />
            )
          }
        />
        <Route
          path="/Movies/:MovieId"
          element={
            IsLoggedIn ? (
              <MovieDetails />
            ) : STATUS === SIGNIN_STATUSES.LOADING ? (
              ""
            ) : (
              <SignIn />
            )
          }
        />
        <Route
          path="/Theatres/:TheatreId"
          element={
            IsLoggedIn ? (
              <TheatreDetails />
            ) : STATUS === SIGNIN_STATUSES.LOADING ? (
              ""
            ) : (
              <SignIn />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
