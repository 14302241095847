import TextField from "@mui/material/TextField";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import {
  ChangePasswordAsync,
  SIGNIN_STATUSES,
  setNewPassword,
} from "../Redux/SignInSlice";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { User, NewPassword, IsLoggedIn, STATUS } = useSelector(
    (state) => state.signin
  );

  return (
    <div className="w-full flex flex-col items-center justify-center p-5 pt-20">
      <form
        action="javascript:void(0)"
        onSubmit={() => {
          dispatch(ChangePasswordAsync());
        }}
        className=" flex flex-col items-center justify-between gap-3 w-full md:w-[25%] p-2 text-sm"
      >
        <TextField
          id="username"
          label="Username"
          variant="outlined"
          required
          className=" w-full text-sm"
          disabled
          value={User.user.emailId}
        />
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          required
          className=" w-full"
          type="password"
          onChange={(e) => dispatch(setNewPassword(e.target.value))}
          value={NewPassword}
        />
        <button
          type="submit"
          // onClick={() => dispatch(SignInAsync())}
          className=" w-full text-sm p-2 py-3 rounded bg-gray-700 text-gray-200 flex justify-center items-center"
        >
          {STATUS === SIGNIN_STATUSES.LOADING ? (
            <SpinnerCircular color="white" size={20} speed={120} />
          ) : (
            "Change Password"
          )}
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
