import React from "react";
const MainCard = (props) => {
  return (
    <div className=" w-[95%] rounded-b-lg p-2">
      <div className="shadow-md shadow-gray-800 rounded-lg">
        <img
          src={"data:image/jpg;base64," + props.Thumbnail + ""}
          alt="Image not found"
          className="rounded-lg"
        />
      </div>
      <div className="p-1 text-lg font-medium text-center">
        <h1>{props.Name}</h1>
      </div>
    </div>
  );
};

export default MainCard;
